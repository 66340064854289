import {useSearchLiftVariationsQuery} from "../services/apiClient";
import React from "react";
import {LiftVariation} from "../Interfaces";
import {Autocomplete, Box, CircularProgress, IconButton, TextField} from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {CircleOutlined} from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import _ from "lodash";
import {eventLogger} from "../services/eventLogger";

export interface LiftVariationSelectorProps {
    className?: string,
    onSelect: (lift: LiftVariation) => void,
    onClear: () => void,
    variation: LiftVariation | string,
    error: boolean,
    showSets: number | boolean
    disabled: boolean
}

function LiftVariationSelector(props: LiftVariationSelectorProps) {
    const [inputValue, setInputValue] = React.useState(typeof props.variation !== "string" ? props.variation.name : "");
    const {data, isLoading} = useSearchLiftVariationsQuery(inputValue, {skip: inputValue.length < 3});
    const [selectedLiftVariation, setSelectedLiftVariation] = React.useState<string | LiftVariation>(props.variation);
    const handleClear = () => {
        props.onClear();
        setInputValue("");
        setSelectedLiftVariation("");
    }
    React.useEffect(() => {
        setInputValue(typeof props.variation !== 'string' ? props.variation.name : "");
        setSelectedLiftVariation(props.variation);
    }, [props.variation]);

    function getFragment() {
        if (isLoading) {
            return <CircularProgress color="primary" size={40}/>;
        }
        if (props.error) {
            return <IconButton><CircleOutlined className={'text-red-600'}/></IconButton>;
        }
        if (props.showSets !== false && props.showSets > 0) {
            return <div className={'text-blue-600 text-center px-2'}>
                <CheckCircleIcon fontSize={"small"}/>
                <p className={'text-xs'}>{props.showSets} sets</p>
            </div>;
        }
        return <IconButton onClick={handleClear}><DeleteOutlineOutlinedIcon/></IconButton>;
    }

    let options: LiftVariation[] = [];
    if (data) {
        options = data.data.result.map((key: number) => {
            if (data.data.entities.liftVariations) {
                return data.data.entities.liftVariations[key];
            } else {
                return null;
            }
        }).filter((liftVariation: LiftVariation | null) => liftVariation);
    }
    return (
        <div className={"grow flex flex-col mt-4"}>
            <Autocomplete
                value={selectedLiftVariation as LiftVariation}
                onChange={(event: any, newValue: string | LiftVariation) => {
                    props.onSelect(newValue as LiftVariation);
                    setSelectedLiftVariation(newValue);
                }}
                disablePortal
                disabled={props.disabled}
                id="search-lift-variations-input"
                selectOnFocus
                clearOnBlur
                autoHighlight
                handleHomeEndKeys
                options={options}
                getOptionLabel={(liftVariation: LiftVariation | string) => {
                    return typeof liftVariation === 'string' ? "" : _.startCase(liftVariation?.name);
                }}
                filterOptions={(x) => x}
                freeSolo
                loading={isLoading}
                disableClearable
                renderInput={(params) => {
                    let label = props.error && !selectedLiftVariation ? "Enter lift type" : "Search lift type...";
                    if(selectedLiftVariation){
                        label = "Lift Type"
                    }
                    return (
                        <TextField
                            {...params}
                            onChange={(e) => {
                                let value = e.currentTarget.value;
                                eventLogger.track("pwa_your_lifts_search_lift", {value});
                                setInputValue(value);
                            }}
                            label={label}
                            error={props.error}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {getFragment()}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }}
                        />
                    );
                }}
                renderOption={(props, option: LiftVariation | string) => {
                    if (typeof option === 'string') {
                        return <></>
                    }
                    return <Box component="li" {...props} key={option.id}>
                        {_.startCase(option.name)}
                    </Box>
                }
                }></Autocomplete>
        </div>
    );
}

export default LiftVariationSelector;
