export interface EditParams {
    resize?: {
        width?: string | number,
        maxWidth?: string | number,
        height?: string | number,
        maxHeight?: string | number,
        fit?: string
    }
}

export const assetUrl = (url: string, size: keyof typeof sizes = "md", edits: EditParams | null = null) => {
    const sizes = {
        xs: 20,
        sm: 30,
        md: 45,
        lg: 60,
        xl: 90,
        xxl: 180
    };
    edits = edits !== null ? edits : {
        resize: {
            width: sizes[size],
            height: sizes[size],
            fit: "cover"
        }
    };
    const imageRequest = JSON.stringify({
        bucket: process.env.REACT_APP_AWS_IMAGE_BUCKET,
        key: url,
        edits
    });
    return process.env.REACT_APP_AWS_IMAGE_ENDPOINT + "/" + btoa(imageRequest);
};
