import {createSlice} from "@reduxjs/toolkit";
import {Group} from "../../Interfaces";
import {RootState} from "../../app/store";

interface GroupState {
    groupId: string | number | null
    group?: Group
}

let initialState: GroupState = {
    groupId: localStorage.getItem("groupId"),
};
export const groupSlice = createSlice({
    name: "group",
    initialState,
    reducers: {
        setGroupId: (state, action) => {
            state.groupId = action.payload;
            localStorage.setItem("groupId", action.payload);
            return state;
        },
        setGroup: (state, action) => {
            state.group = action.payload;
            return state;
        }
    }
});

export const selectGroup = (state: RootState) => state.group.group;
export const selectGroupId = (state: RootState) => state.group.groupId;
export const {setGroupId, setGroup} = groupSlice.actions;
export default groupSlice.reducer;
