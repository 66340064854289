import {DateTime} from "luxon";
import React from "react";

interface WorkoutSummaryDateProps {
    date: string,
    className?: string
}

function WorkoutSummaryDate(props: WorkoutSummaryDateProps) {
    let date = DateTime.fromISO(props.date).setZone("UTC");
    return <p className={`text-center ${props.className}`}><strong>{date.toFormat("EEE d MMM")}</strong></p>;
}

export default WorkoutSummaryDate;
