import LiftDataEntry, {LiftSubmission} from "./LiftDataEntry";
import React from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {Button, useTheme} from "@mui/material";
import {liftsDataIsValid} from "../pages/LiftEntry";
import {LoadingButton} from "@mui/lab";
import {eventLogger} from "../services/eventLogger";
import LogoutButton from "./LogoutButton";
import {useAppSelector} from "../app/hooks";
import {selectUser} from "../features/auth/authSlice";

export interface LiftsSubmission {
    lifts: LiftSubmission[],
    groups?: number[]
}

interface LiftsDataEntryProps {
    onSubmit: (data: LiftsSubmission) => void,
    data: LiftsSubmission,
    errors: any,
    saving: boolean
}

function LiftsDataEntry(props: LiftsDataEntryProps) {
    const theme = useTheme();
    const user = useAppSelector(selectUser);
    const [activeLift, setActiveLift] = React.useState(0);
    let emptyLift = {liftVariation: "", sets: [{weight: "", reps: ""}]};
    const [liftsData, setLiftsData] = React.useState<LiftsSubmission>(props.data);
    const handleChange = (lift: LiftSubmission, index: number) => {
        let lifts = liftsData.lifts;
        lifts[index] = lift;
        setLiftsData({lifts});
    }

    const handleAddLift = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        let lifts = liftsData.lifts;
        lifts.push({...emptyLift});
        eventLogger.track("pwa_your_lifts_add_lift", {lifts});
        setLiftsData({lifts});
        setActiveLift(lifts.length - 1);
    }

    const handleRemoveLift = (i: number) => {
        let lifts = liftsData.lifts.filter((lift, k) => k !== i);
        if (lifts.length === 0) {
            lifts = [{...emptyLift}];
        }
        eventLogger.track("pwa_your_lifts_remove_lift", {lifts});
        setLiftsData({lifts});
        setActiveLift(lifts.length - 1);
    }


    let liftRows = liftsData.lifts.map((lift, i) => {
        const validSets = lift.sets.filter(set => {
            return +set.weight > 0 && +set.reps > 0;
        }).length;
        return <LiftDataEntry key={i}
                              lift={lift}
                              onChange={lift => handleChange(lift, i)}
                              onClear={() => handleRemoveLift(i)}
                              collapsed={activeLift !== i}
                              error={activeLift !== i && (!lift.liftVariation || validSets !== lift.sets.length)}
                              onClick={e => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setActiveLift(i);
                              }}
                              errors={props.errors && props.errors.lifts && props.errors.lifts[i] ? props.errors.lifts[i] : ""}/>;
    })
    return <form className={'flex flex-col grow -mb-6'}>
        <div className={'flex-1 container mx-auto max-w-xl'} onClick={() => setActiveLift(-1)}>
            {liftRows}
            <div className={'text-center mt-4'}>
                <Button variant={"text"} color={'secondary'} sx={{textTransform: "none", fontSize: "14px"}} onClick={e => handleAddLift(e)} startIcon={<AddCircleIcon/>}>
                    Add Lift
                </Button>
            </div>
        </div>
        <div className={'flex-grow-0 pt-4 sticky z-10 -mx-6 px-6 pb-6'} style={{bottom: 0, left: 0, right: 0, backgroundColor: theme.footer.background}}>
            <div className={"container mx-auto max-w-xl"}>
                <LoadingButton loading={props.saving} variant={"contained"} size={'large'} className={'w-full'} onClick={() => {

                    eventLogger.track("pwa_your_lifts_next", liftsData);
                    props.onSubmit(liftsData);
                }}
                               disabled={!liftsDataIsValid(liftsData)}>Next</LoadingButton>
                {user &&
                    <div className={'mt-4'}>
                        <LogoutButton/>
                    </div>
                }
            </div>
        </div>
    </form>
}

export default LiftsDataEntry
