import {useAppDispatch, useAppSelector} from "../app/hooks";
import {selectGroupId} from "../features/group/groupSlice";
import {brawnApi} from "../services/apiClient";
import {useSelector} from "react-redux";
import {RootState} from "../app/store";
import React from "react";
import {selectGroupById} from "../features/entities/entitiesSlice";

interface GroupNameDisplayProps {
    onClick: () => void,
    className?: string
}

const initialProps: GroupNameDisplayProps = {
    onClick: () => null,
    className: ""
}

function GroupNameDisplay(props: GroupNameDisplayProps = initialProps) {
    const [isLoading, setIsLoading] = React.useState(false);
    let dispatch = useAppDispatch();
    const groupId = useAppSelector(selectGroupId);
    let cachedGroup = useSelector((state: RootState) => {
        return groupId ? selectGroupById(state.entities, groupId) : null;
    });
    React.useEffect(() => {
        if (!cachedGroup && groupId && !isLoading) {
            setIsLoading(true);
            dispatch(brawnApi.endpoints.getGroup.initiate(groupId))
                .unwrap()
                .then(response => {
                    setIsLoading(false);
                })
        }
    }, [cachedGroup, dispatch, groupId, isLoading])

    if (cachedGroup) {
        return <div className={"text-white uppercase underline " + props.className} onClick={props.onClick}>{cachedGroup.display_name}</div>
    }
    return <></>
}

export default GroupNameDisplay;
