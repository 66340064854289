import apiClient from "./apiClient";
import {authService} from "./auth.service";
import mixpanel from "mixpanel-browser";

interface EngagementData {
    event: string,
    meta?: any,
    source: string,
    user_id?: number
}

const track = (event: string, meta: any = null) => {
    let user = authService.retrieveUserFromStorage();
    let data: EngagementData = {
        event,
        source: 'pwa',
        meta
    };
    if (user && user.id) {
        data.user_id = user.id;
    }
    apiClient.post('/api/engagement/send-event', data);
    let token = process.env.REACT_APP_MIX_PANEL_TOKEN as string;
    mixpanel.init(token, {debug: process.env.NODE_ENV !== 'production'});
    mixpanel.track(event, meta);
}

const reset = () => {
    let token = process.env.REACT_APP_MIX_PANEL_TOKEN as string;
    mixpanel.init(token, {debug: process.env.NODE_ENV !== 'production'});
    mixpanel.reset();
}

export const eventLogger = {track, reset};
