import {ReactComponent as LiftsIcon} from "../../icons/icon_lifts.svg";
import {ReactComponent as SetsIcon} from "../../icons/icon_sets.svg";
import {ReactComponent as RepsIcon} from "../../icons/icon_reps.svg";
import {ReactComponent as VolumeIcon} from "../../icons/icon_weight.svg";
import React from "react";

interface StatProps extends React.PropsWithChildren {
    value: number;
    label: string;
}

function Stat(props: StatProps) {
    return <div className={"flex flex-col content-center"}>
        <div className={'bg-black/25 p-3 rounded-full w-14 h-14 mb-2 flex items-center justify-center'}>
            {props.children}
        </div>
        <p className={'text-center text-white flex-1 text-lg'}><strong>{props.value.toLocaleString()}</strong></p>
        <p className={'text-center text-white/50 flex-1 text-xs'}>{props.label}</p>
    </div>
}

interface StatBarProps {
    lifts: number;
    sets: number;
    reps: number;
    volume: number;
    volumeUnit: string;
    className?: string;
}

function StatBar(props: StatBarProps) {
    return <div className={`flex justify-between ${props.className}`}>
        <Stat value={props.lifts} label={'Lifts'}>
            <LiftsIcon/>
        </Stat>
        <Stat value={props.sets} label={'Sets'}>
            <SetsIcon/>
        </Stat>
        <Stat value={props.reps} label={'Reps'}>
            <RepsIcon/>
        </Stat>
        <Stat value={props.volume} label={`Volume (${props.volumeUnit})`}>
            <VolumeIcon/>
        </Stat>
    </div>
}

export default StatBar;
