import {createTheme} from "@mui/material";

const pureGymTheme = createTheme({
    logo: "/images/pure-gym-logo.svg",
    landing: {
        background: "#212121",
        image: "/images/pure-gym-landing.png",
        textColour: "#FFF"
    },
    header: {
        image: "/images/pg_condensed_2.svg",
        imageMaxHeight: "1rem",
        background: "#212121",
        padding: {
            top: "50px",
            right: "25px",
            left: "25px",
            bottom: "25px",
        }
    },
    palette: {
        mode: "light",
        primary: {
            main: "#FF6129"
        },
        secondary: {
            main: "#0DC3B6"
        }
    },
    workoutSummary: {
        box: {
            background: "#FFF",
            boxShadow: "0px 0px 6px 4px rgba(0, 0, 0, 0.05)",
            borderRadius: "8px",
            table: {
                headerColour: "#000",
                cellColour: "rgba(0, 0, 0, 0.5)",
                rowHeaderColour: "#479298"
            },
            totals: {
                titleColour: "#000",
                valueColour: "rgba(0,0,0, 0.5)"
            }
        }
    },
    appBackground: "#FFFFFF",
    typography: {
        fontFamily: ["'Abel Pro'", "Helvetica", "Arial", "sans-serif"].join(","),
        h1: {
            fontSize: "48px",
            fontWeight: 600
        },
        h2: {
            fontSize: "30px",
            fontWeight: 500
        },
        h3: {
            fontSize: "16px",
            fontWeight: 700
        },
        button: {
            textTransform: "none"
        }
    },
    footer: {
        background: "#FFFFFF"
    }
});

export default pureGymTheme;
