import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {selectLiftVariationEntities, selectTopSetEntities, selectWorkoutSessionById} from "../../features/entities/entitiesSlice";
import {useAppDispatch} from "../../app/hooks";
import {brawnApi} from "../../services/apiClient";
import {CircularProgress, useTheme} from "@mui/material";
import {TopSet} from "../../Interfaces";
import React from "react";
import _ from "lodash";

interface WorkoutBreakdownProps {
    workoutSessionId: number;
    className?: string
}

function WorkoutBreakdown(props: WorkoutBreakdownProps) {
    const theme = useTheme();
    let {workoutSessionId} = props;
    let dispatch = useAppDispatch();
    let workoutSession = useSelector((state: any) => selectWorkoutSessionById(state.entities, +workoutSessionId));
    let liftVariationEntities = useSelector((state: RootState) => selectLiftVariationEntities(state.entities));
    if (!workoutSession && workoutSessionId) {
        dispatch(brawnApi.endpoints.getWorkoutSession.initiate(workoutSessionId));
    }
    let topSetEntities = useSelector((state: RootState) => selectTopSetEntities(state.entities));
    if (!workoutSession) {
        return <CircularProgress/>;
    }
    let topSets: TopSet[] = [];
    if (workoutSession && topSetEntities) {
        topSets = workoutSession.top_sets.map(id => (typeof id === 'number' ? topSetEntities[id] : id) as TopSet);
    }
    let lifts: any = {};
    topSets.forEach(topSet => {
        let lift = lifts[topSet.lift_variation_id] ? lifts[topSet.lift_variation_id] : {};
        if (!lift.liftVariation) {
            lift.liftVariation = liftVariationEntities[topSet.lift_variation_id];
        }
        let sets = lift.sets ? lift.sets : [];
        sets.push(topSet);
        lift.sets = sets;
        lifts[topSet.lift_variation_id] = lift;
    });
    let liftBlocks = Object.keys(lifts).map(id => {
        let lift = lifts[id];
        let setRows = _.sortBy(lift.sets, "set_number").map(set => {
            return <div className={'grid grid-cols-6 pb-3'} key={set.id}>
                <div className={"col-span-3 text-xs"} style={{color: theme.workoutSummary.box.table.rowHeaderColour}}>Set {set.set_number}</div>
                <div className={"text-xs"} style={{color: theme.workoutSummary.box.table.cellColour}}>{set.reps}</div>
                <div className={"text-xs"} style={{color: theme.workoutSummary.box.table.cellColour}}>{set.weight}kg</div>
                <div className={"text-xs"} style={{color: theme.workoutSummary.box.table.cellColour}}>{set.bips}</div>
            </div>
        });
        return <div key={id} className={"px-3 pt-3 mb-6"} style={{
            background: theme.workoutSummary.box.background,
            borderRadius: theme.workoutSummary.box.borderRadius,
            boxShadow: theme.workoutSummary.box.boxShadow
        }}>
            <div className={''}>
                <div className={'grid grid-cols-6 mb-3'}>
                    <div className={"whitespace-nowrap col-span-3 text-xs"} style={{color: theme.workoutSummary.box.table.headerColour}}>
                        <strong>{_.startCase(lift.liftVariation.name)}</strong></div>
                    <div className={"text-xs"} style={{color: theme.workoutSummary.box.table.headerColour}}><strong>Reps</strong></div>
                    <div className={"text-xs"} style={{color: theme.workoutSummary.box.table.headerColour}}><strong>Weight</strong></div>
                    <div className={"text-xs"} style={{color: theme.workoutSummary.box.table.headerColour}}><strong>BIPs</strong></div>
                </div>
                {setRows}
            </div>
        </div>;
    });
    return <div className={props.className}>
        <h3 className={'text-xl mb-3'}><strong>Breakdown</strong></h3>
        {liftBlocks}
    </div>
}

export default WorkoutBreakdown;
