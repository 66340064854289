import {CssBaseline, ThemeProvider} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {selectTheme, setTheme} from "../features/theme/themeSlice";
import React from "react";
import pureGymTheme from "./themes/pure-gym";
import brawnTheme from "./themes/brawn";
import smartFitTheme from "./themes/smart-fit";

declare module '@mui/material/styles' {
    interface Theme {
        logo?: string;
        landing: {
            image?: string,
            background?: string,
            textColour?: string
        }
        header: {
            image?: string,
            background?: string,
            imageMaxHeight?: string,
            padding: {
                top: string,
                right: string,
                left: string,
                bottom: string,
            }
        }
        workoutSummary: {
            box: {
                background?: string,
                boxShadow?: string,
                border?: string,
                borderRadius?: string,
                table: {
                    cellColour?: string,
                    headerColour?: string,
                    rowHeaderColour?: string
                },
                totals: {
                    titleColour: string,
                    valueColour: string
                }
            }
        }
        brawn?: boolean;
        appBackground?: React.CSSProperties['color'];
        footer: {
            background?: string
        }
    }

    interface ThemeOptions {
        logo?: string
        landing: {
            image?: string,
            background?: string,
            textColour?: string
        },
        header: {
            image?: string,
            background?: string,
            imageMaxHeight?: string,
            padding: {
                top: string,
                right: string,
                left: string,
                bottom: string,
            }
        }
        workoutSummary: {
            box: {
                background?: string,
                boxShadow?: string,
                border?: string,
                borderRadius?: string,
                table: {
                    cellColour?: string,
                    headerColour?: string,
                    rowHeaderColour?: string
                },
                totals: {
                    titleColour: string,
                    valueColour: string
                }
            }
        }
        brawn?: boolean;
        appBackground?: React.CSSProperties['color'];
        footer: {
            background?: string
        }
    }
}

interface Props extends React.PropsWithChildren {
}

function Theme(props: Props) {
    const dispatch = useAppDispatch();
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    let themeChoice = params.get("theme");
    let currentTheme = useAppSelector(selectTheme);
    if (themeChoice) {
        dispatch(setTheme(themeChoice));
    }
    let theme = brawnTheme;
    switch (currentTheme) {
        case "pure-gym":
            theme = pureGymTheme;
            break;
        case "smart-fit":
            theme = smartFitTheme;
            break;
    }

    return <ThemeProvider theme={theme}>
        <CssBaseline/>
        {props.children}
    </ThemeProvider>
}

export default Theme;
