import {useParams} from "react-router-dom";
import React from "react";
import {useGetWorkoutSessionsByUserQuery} from "../../services/apiClient";
import {CircularProgress, IconButton} from "@mui/material";
import WorkoutSummary from "../../ui/workout-summary/WorkoutSummary";
import WorkoutSummaryDate from "../../ui/workout-summary/WorkoutSummaryDate";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";

function IntegrationWorkoutHistory() {
    let [tab, setTab] = React.useState(0);
    let params = useParams();
    let userId = params['userId'] ? +params['userId'] : -1;
    const {
        data: workouts,
        isFetching,
        isLoading,
        error
    } = useGetWorkoutSessionsByUserQuery(userId);

    if (isLoading || isFetching) {
        return <div className={"flex py-6 justify-center"}><CircularProgress/></div>;
    }
    let noData = <div className={'flex flex-col h-screen'}>
        <div className={'px-6 grow mt-6 container mx-auto max-w-xl'}>
            <p className={`text-center text-white/50`}>No history to display</p>
        </div>
    </div>;
    if (error) {
        return noData;
    }
    if (workouts.data.result.length === 0) {
        return noData
    }
    let currentWorkoutId = workouts.data.result[tab];
    let currentWorkout = workouts.data.entities.workoutSessions[currentWorkoutId];
    return <div className={'flex flex-col h-screen'}>
        <div className={'px-6 grow mt-6 container mx-auto max-w-xl'}>
            <div className={"flex items-center py-6"}>
                <div className={"flex-none"}>
                    <IconButton onClick={() => setTab(tab + 1)} disabled={tab === (workouts.data.result.length - 1)}>
                        <ChevronLeft/>
                    </IconButton>
                </div>
                <WorkoutSummaryDate date={currentWorkout.created_at} className={'flex-grow'}/>
                <div className={"flex-none"}>
                    <IconButton onClick={() => setTab(tab - 1)} disabled={tab === 0}>
                        <ChevronRight/>
                    </IconButton>
                </div>
            </div>
            <div>
                <WorkoutSummary workoutSessionId={currentWorkoutId}/>
            </div>
        </div>
    </div>
}

export default IntegrationWorkoutHistory;
