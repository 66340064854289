import {createTheme} from "@mui/material";

const brawnTheme = createTheme({
    logo: "/logo_white.png",
    landing: {
        background: "#201D2C",
        image: "/images/landing.png",
        textColour: "#FFF"
    },
    header: {
        image: "/logo_white.png",
        imageMaxHeight: "1rem",
        padding: {
            top: "50px",
            right: "25px",
            left: "25px",
            bottom: "25px",
        }
    },
    workoutSummary: {
        box: {
            background: "rgba(0,0,0,0.25)",
            border: "#FD2B12",
            table: {
                headerColour: "rgba(255, 255, 255)",
                cellColour: "rgba(255, 255, 255, 0.5)",
                rowHeaderColour: "rgba(255, 255, 255, 0.5)"
            },
            totals: {
                titleColour: "#FFF",
                valueColour: "#FFF"
            },
            borderRadius: "12px"
        }
    },
    brawn: true,
    palette: {
        mode: "dark",
        primary: {
            main: "#FD2B12"
        },
        secondary: {
            main: "#FD2B12"
        },
    },
    appBackground: "#201D2C",
    typography: {
        fontFamily: ["'Barlow'", "sans-serif"].join(","),
        h1: {
            fontSize: "48px",
            fontWeight: 600
        },
        h2: {
            fontSize: "30px",
            fontWeight: 500
        },
        h3: {
            fontSize: "16px",
            fontWeight: 700
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ownerState}) => ({
                    ...(ownerState.variant === 'text' && {
                        color: '#fff',
                    }),
                    ...(ownerState.variant === 'outlined' && ownerState.color === 'primary' && {
                        color: '#fff',
                    }),
                }),
            }
        }
    },
    footer: {
        background: "transparent"
    }
});

export default brawnTheme;
