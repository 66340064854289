import {createEntityAdapter, createSlice, EntityAdapter, EntityState} from "@reduxjs/toolkit";
import {WritableDraft} from "immer/dist/internal";
import {Group, GroupLeaderboard, Gym, InGymScreenConfig, Insight, LiftVariation, Stat, TopSet, User, WorkoutSession} from "../../Interfaces";
import {schema} from "normalizr";

export const LIFT_VARIATION = "liftVariations";
export const GROUP = "groups";
export const WORKOUT_SESSION = "workoutSessions";
export const TOP_SET = 'topSets';
export const USER = 'users';
export const GYM = 'gyms';
export const LEADERBOARD = 'leaderboards';
export const SCREEN_CONFIG = 'screenConfigs';
export const INSIGHTS = 'insights';
export const STATS = 'stats';

export const userSchema = new schema.Entity(USER);
export const gymSchema = new schema.Entity(GYM);
export const leaderboardSchema = new schema.Entity(LEADERBOARD);
export const screenConfigSchema = new schema.Entity(SCREEN_CONFIG);
export const groupSchema = new schema.Entity(GROUP, {
    admins: [userSchema],
    gyms: [gymSchema],
    leaderboards: [leaderboardSchema],
    screen_configs: [screenConfigSchema],
});
export const liftVariationSchema = new schema.Entity(LIFT_VARIATION)
export const topSetSchema = new schema.Entity(TOP_SET, {
    user: userSchema,
    lift_variation: liftVariationSchema
})
export const workoutSessionSchema = new schema.Entity(WORKOUT_SESSION, {
    top_sets: [topSetSchema],
    groups: [groupSchema]
});
export const insightSchema = new schema.Entity(INSIGHTS, {
    top_set: topSetSchema,
    user: userSchema
});
export const statSchema = new schema.Entity(STATS, {
    top_set: topSetSchema,
    user: userSchema
})

export const groupsAdapter = createEntityAdapter<Group>({
    selectId: model => model.id
});

export const {
    selectById: selectGroupById,
    selectIds: selectGroupIds,
    selectEntities: selectGroupEntities,
    selectAll: selectAllGroups,
    selectTotal: selectTotalGroups,
} = groupsAdapter.getSelectors((state: any) => state.groups);

export const liftVariationsAdapter = createEntityAdapter<LiftVariation>({
    selectId: model => model.id
});

export const {
    selectById: selectLiftVariationById,
    selectIds: selectLiftVariationIds,
    selectEntities: selectLiftVariationEntities,
    selectAll: selectAllLiftVariations,
    selectTotal: selectTotalLiftVariations,
} = liftVariationsAdapter.getSelectors((state: any) => state.liftVariations);

export const workoutSessionsAdapter = createEntityAdapter<WorkoutSession>({
    selectId: model => model.id
});

export const {
    selectById: selectWorkoutSessionById,
    selectIds: selectWorkoutSessionIds,
    selectEntities: selectWorkoutSessionEntities,
    selectAll: selectAllWorkoutSessions,
    selectTotal: selectTotalWorkoutSessions,
} = workoutSessionsAdapter.getSelectors((state: any) => state.workoutSessions);

export const topSetsAdapter = createEntityAdapter<TopSet>({
    selectId: model => model.id
});

export const {
    selectById: selectTopSetById,
    selectIds: selectTopSetIds,
    selectEntities: selectTopSetEntities,
    selectAll: selectAllTopSets,
    selectTotal: selectTotalTopSets,
} = topSetsAdapter.getSelectors((state: any) => state.topSets);

export const usersAdapter = createEntityAdapter<User>({
    selectId: model => model.id
});

export const {
    selectById: selectUserById,
    selectIds: selectUserIds,
    selectEntities: selectUserEntities,
    selectAll: selectAllUsers,
    selectTotal: selectTotalUsers,
} = usersAdapter.getSelectors((state: any) => state.users);

export const gymsAdapter = createEntityAdapter<Gym>({
    selectId: model => model.id
});

export const {
    selectById: selectGymById,
    selectIds: selectGymIds,
    selectEntities: selectGymEntities,
    selectAll: selectAllGyms,
    selectTotal: selectTotalGyms,
} = gymsAdapter.getSelectors((state: any) => state.gyms);

export const leaderboardsAdapter = createEntityAdapter<GroupLeaderboard>({
    selectId: model => model.id
});

export const {
    selectById: selectLeaderboardById,
    selectIds: selectLeaderboardIds,
    selectEntities: selectLeaderboardEntities,
    selectAll: selectAllLeaderboards,
    selectTotal: selectTotalLeaderboards,
} = leaderboardsAdapter.getSelectors((state: any) => state.leaderboards);

export const screenConfigsAdapter = createEntityAdapter<InGymScreenConfig>({
    selectId: model => model.id
});

export const {
    selectById: selectScreenConfigById,
    selectIds: selectScreenConfigIds,
    selectEntities: selectScreenConfigEntities,
    selectAll: selectAllScreenConfigs,
    selectTotal: selectTotalScreenConfigs,
} = screenConfigsAdapter.getSelectors((state: any) => state.screenConfigs);

export const insightsAdapter = createEntityAdapter<Insight>({
    selectId: model => model.id,
})

export const {
    selectById: selectInsightsByWorkoutSessionId,
    selectEntities: selectInsightsEntities
} = insightsAdapter.getSelectors((state: any) => state.insights);

export const statsAdapter = createEntityAdapter<Stat>({
    selectId: model => model.id
})

export const {
    selectEntities: selectStatEntities
} = statsAdapter.getSelectors((state: any) => state.stats);

// entities state
export const entitiesInitialState = {
    groups: groupsAdapter.getInitialState(),
    liftVariations: liftVariationsAdapter.getInitialState(),
    workoutSessions: workoutSessionsAdapter.getInitialState(),
    topSets: topSetsAdapter.getInitialState(),
    users: usersAdapter.getInitialState(),
    gyms: gymsAdapter.getInitialState(),
    leaderboards: leaderboardsAdapter.getInitialState(),
    screenConfigs: screenConfigsAdapter.getInitialState(),
    insights: insightsAdapter.getInitialState(),
    stats: statsAdapter.getInitialState()
};

function getEntityAdapter(key: string, state: WritableDraft<any>) {
    let adapter = getAdapter(key) as EntityAdapter<any>;
    let entityState = state[key] as EntityState<any>;
    return {adapter, entityState};
}

function getAdapter(key: string) {
    switch (key) {
        case GROUP:
            return groupsAdapter;
        case LIFT_VARIATION:
            return liftVariationsAdapter;
        case WORKOUT_SESSION:
            return workoutSessionsAdapter;
        case TOP_SET:
            return topSetsAdapter;
        case USER:
            return usersAdapter;
        case GYM:
            return gymsAdapter;
        case LEADERBOARD:
            return leaderboardsAdapter;
        case SCREEN_CONFIG:
            return screenConfigsAdapter;
        case INSIGHTS:
            return insightsAdapter;
        case STATS:
            return statsAdapter;
        default:
            return null;
    }
}

const entitiesSlice = createSlice({
    name: "entities",
    initialState: entitiesInitialState,
    reducers: {
        upsertMany(state, action) {
            let entities = action.payload.data.entities;
            Object.keys(entities).forEach(key => {
                let {adapter, entityState} = getEntityAdapter(key, state);
                if (adapter && entityState) {
                    adapter.upsertMany(entityState, entities[key]);
                }
            })
        },
        upsertOne(state, action) {
            let entities = action.payload.data.entities;
            Object.keys(entities).forEach(key => {
                let {adapter, entityState} = getEntityAdapter(key, state);
                if (adapter && entityState) {
                    adapter.upsertMany(entityState, entities[key]);
                }
            })
        },
    }
})


export const {upsertMany, upsertOne} = entitiesSlice.actions;

export default entitiesSlice.reducer;
