export const Routes = {
    LANDING: "/",
    LIFT_ENTRY: "/lift-entry",
    RESET_PIN: "/pin/reset",
    WORKOUT_SESSION_SUMMARY: "/workout-session/:workoutSessionId/summary",
    GROUP_SELECT: "/group/select",
    LOGIN: "/login",
    REGISTER: "/register",
    APP_WORKOUT_SESSION_SUMMARY: "/app/workout-session/:workoutSessionId/summary",
    APP_TRAINING_HISTORY: "/app/user/:userId/training-history",
    APP_BIPS_HISTORY: "/app/user/:userId/bips-history",
    APP_BIPS_TARGET: "/app/bips-target"
};
