import React from 'react';
import './App.css';
import AppRouter from "./AppRouter";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {clientLogin, selectStatus, selectToken} from "./features/auth/authSlice";
import {selectGroupId, setGroupId} from "./features/group/groupSlice";
import Theme from "./ui/Theme";
import Layout from "./ui/Layout";
import {authService} from "./services/auth.service";

function App() {
    const dispatch = useAppDispatch();
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    let token = useAppSelector(selectToken);
    if (params.get("token")) {
        token = params.get("token") as string;
        decodeURI(token);
        authService.handleToken(decodeURI(token));
    }
    const authStatus = useAppSelector(selectStatus);
    let groupId = params.get("group");
    let currentGroupId = useAppSelector(selectGroupId);
    if (groupId && groupId !== currentGroupId) {
        dispatch(setGroupId(groupId));
    }
    React.useEffect(() => {
        if (!token && authStatus === 'idle') {
            dispatch(clientLogin())
                .unwrap()
                .then();
        }
    }, [token, authStatus, dispatch])
    return <Theme>
        <Layout>
            <AppRouter/>
        </Layout>
    </Theme>;
}

export default App;
