import LiftVariationSelector from "./LiftVariationSelector";
import React from "react";
import {LiftVariation} from "../Interfaces";
import SetDataEntry from "./SetDataEntry";
import {Button, Collapse} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {eventLogger} from "../services/eventLogger";

export interface Set {
    weight: number | string,
    reps: number | string
}

export interface LiftSubmission {
    liftVariation: LiftVariation | string,
    sets: Set[]
}

interface LiftDataEntryProps {
    lift: LiftSubmission,
    onChange: (lift: LiftSubmission) => void,
    onClear: () => void,
    errors: any
    collapsed: boolean,
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    error: boolean
}

function LiftDataEntry(props: LiftDataEntryProps) {
    const handleSetChange = (index: number, set: Set) => {
        let sets = props.lift.sets;
        sets[index] = set;
        let lift = {...props.lift, sets};
        props.onChange(lift)
    }

    const handleLiftVariationChange = (liftVariation: LiftVariation) => {
        props.onChange({...props.lift, liftVariation});
    }

    const handleSetRemoval = (i: number) => {
        let sets = props.lift.sets.filter((set, k) => k !== i);
        if (sets.length === 0) {
            sets = [{reps: "", weight: ""}];
        }
        let lift = {...props.lift, sets};
        eventLogger.track("pwa_your_lifts_remove_set", {lift});
        props.onChange(lift);
    }

    const handleAddSet = () => {
        let sets = props.lift.sets;
        sets.push({weight: "", reps: ""});
        let lift = {...props.lift, sets};
        eventLogger.track("pwa_your_lifts_add_set", {lift});
        props.onChange(lift);
    }

    const setRows = props.lift.sets.map((set, i) => <SetDataEntry key={i}
                                                                  setNumber={i + 1}
                                                                  set={set}
                                                                  onChange={(set) => handleSetChange(i, set)}
                                                                  onDelete={() => handleSetRemoval(i)}
                                                                  hideDelete={i === 0 && props.lift.sets.length === 1}
                                                                  errors={props.errors && props.errors.sets && props.errors.sets[i] ? props.errors.sets[i] : ""}/>)
    return (
        <Collapse in={!props.collapsed} collapsedSize={75} onClick={props.onClick}>
            <div className={"border-b border-white/25 pb-4"}>
                <LiftVariationSelector onSelect={handleLiftVariationChange} onClear={props.onClear} variation={props.lift.liftVariation} error={props.error}
                                       showSets={props.collapsed && !props.error ? props.lift.sets.length : false} disabled={props.collapsed}/>
                {setRows}
                <div className={'mt-4'}>
                    <Button variant={"text"} color={'secondary'} sx={{textTransform: "none", fontSize: "14px"}} onClick={handleAddSet} startIcon={<AddCircleIcon/>}>
                        Add Set
                    </Button>
                </div>
            </div>
        </Collapse>
    )
}

export default LiftDataEntry
