import {styled, Tab, TabProps} from "@mui/material";
import React from "react";

interface StyledTabProps extends TabProps{}

const BrawnTab = styled((props: StyledTabProps) => <Tab  {...props} />)(
    ({ theme }) => ({
        textTransform: 'uppercase',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: "16px",
        // margin: '0 2.5rem',
        '&.MuiButtonBase-root': {
            color: theme.palette.getContrastText(theme.header.background ? theme.header.background : "#000"),
            opacity: 0.25
        },
        '&.Mui-selected': {
            color: theme.palette.getContrastText(theme.header.background ? theme.header.background : "#000"),
            fontWeight: theme.typography.fontWeightBold,
            opacity: 1
        }
    }),
);

export default BrawnTab;
