import React from "react";
import {Theme, useTheme} from "@mui/material";

interface Props extends React.PropsWithChildren {
}

function Layout(props: Props){
    const theme: Theme = useTheme();
    return <div className={'min-w-screen h-screen flex flex-col justify-center content-center'} style={{fontFamily: theme.typography.fontFamily}}>
            <div className={"colour-bg"} style={{backgroundColor: theme.appBackground}}/>
            <div className={"img-bg"}/>
            <div className="content">
                {props.children}
            </div>
        </div>
}

export default Layout;
