import type {Middleware, MiddlewareAPI} from '@reduxjs/toolkit'
import {isRejectedWithValue} from '@reduxjs/toolkit'
import {authService} from "../services/auth.service";

export const authorised: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if (action.payload.status === 401) {
            authService.logout();
        }
    }

    return next(action)
}
