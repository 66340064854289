import {Route, Routes} from "react-router-dom";
import {Routes as Paths} from "./RouteConstants";
import Landing from "./pages/Landing";
import LiftEntry from "./pages/LiftEntry";
import WorkoutSessionSummary from "./pages/WorkoutSessionSummary";
import ResetPin from "./pages/ResetPin";
import SelectGroup from "./pages/SelectGroup";
import IntegrationWorkoutSessionSummary from "./pages/integration/IntegrationWorkoutSessionSummary";
import IntegrationWorkoutHistory from "./pages/integration/IntegrationWorkoutHistory";
import IntegrationBipsHistory from "./pages/integration/IntegrationBipsHistory";
import IntegrationBipsTarget from "./pages/integration/IntegrationBipsTarget";

function AppRouter() {
    return <Routes>
        <Route path={Paths.LIFT_ENTRY} element={<LiftEntry/>}/>
        <Route path={Paths.WORKOUT_SESSION_SUMMARY} element={<WorkoutSessionSummary/>}/>
        <Route path={Paths.RESET_PIN} element={<ResetPin/>}/>
        <Route path={Paths.GROUP_SELECT} element={<SelectGroup/>}/>
        <Route path={Paths.APP_WORKOUT_SESSION_SUMMARY} element={<IntegrationWorkoutSessionSummary/>}/>
        <Route path={Paths.APP_TRAINING_HISTORY} element={<IntegrationWorkoutHistory/>}/>
        <Route path={Paths.APP_BIPS_HISTORY} element={<IntegrationBipsHistory/>}/>
        <Route path={Paths.APP_BIPS_TARGET} element={<IntegrationBipsTarget/>}/>
        <Route path={Paths.LANDING} element={<Landing/>}/>
        <Route path={"*"} element={<Landing/>}/>
    </Routes>
}

export default AppRouter;
