import {useParams} from "react-router-dom";
import React from "react";
import WorkoutSummary from "../../ui/workout-summary/WorkoutSummary";
import WorkoutSummaryDate from "../../ui/workout-summary/WorkoutSummaryDate";
import {useAppDispatch} from "../../app/hooks";
import {useSelector} from "react-redux";
import {selectWorkoutSessionById} from "../../features/entities/entitiesSlice";
import {brawnApi} from "../../services/apiClient";
import {CircularProgress} from "@mui/material";

function IntegrationWorkoutSessionSummary() {
    let params = useParams();
    let workoutSessionId = params['workoutSessionId'] ? +params['workoutSessionId'] : -1;
    let dispatch = useAppDispatch();
    let workoutSession = useSelector((state: any) => selectWorkoutSessionById(state.entities, +workoutSessionId));
    if (!workoutSession && workoutSessionId) {
        dispatch(brawnApi.endpoints.getWorkoutSession.initiate(workoutSessionId));
    }
    if (!workoutSession) {
        return <CircularProgress/>;
    }
    return <div className={'flex flex-col h-screen'}>
        <div className={'px-6 grow mt-6 container mx-auto max-w-xl'}>
            <WorkoutSummaryDate date={workoutSession.created_at} className={"mb-6"}/>
            <WorkoutSummary workoutSessionId={workoutSessionId}/>
        </div>
    </div>
}

export default IntegrationWorkoutSessionSummary
