import {Middleware} from "@reduxjs/toolkit";
import {RootState} from "../app/store";
import {upsertMany, upsertOne} from "../features/entities/entitiesSlice";

export const entities: Middleware<{}, RootState> = store => next => action => {
    if ((action.type === "brawnApi/executeQuery/fulfilled" || action.type === 'brawnApi/executeMutation/fulfilled') && action.payload.key) {
        if (Array.isArray(action.payload)) {
            store.dispatch(upsertMany(action.payload));
        } else {
            store.dispatch(upsertOne(action.payload));
        }
    }
    return next(action);
}
