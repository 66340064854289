import {brawnApi} from "../services/apiClient";
import {assetUrl, EditParams} from "../services/image";
import {useSelector} from "react-redux";
import {useAppDispatch} from "../app/hooks";
import {selectGroupById} from "../features/entities/entitiesSlice";

export interface GroupLogoProps {
    groupId: string | number,
    edits: EditParams,
    className?: string
}

function GroupLogo(props: GroupLogoProps) {
    let group = useSelector((state: any) => selectGroupById(state.entities, props.groupId));
    let dispatch = useAppDispatch();
    if (!group && props.groupId) {
        dispatch(brawnApi.endpoints.getGroup.initiate(props.groupId));
    }
    if (group && group.logo_for_dark_background_url) {
        let url = assetUrl(group.logo_for_dark_background_url, 'lg', props.edits);
        return (<img src={url} alt={group.display_name} className={props.className}/>);
    }
    return <></>;
}

export default GroupLogo;
