import {useSearchUsersQuery} from "../services/apiClient";
import React from "react";
import {User} from "../Interfaces";
import {Autocomplete, Avatar, Box, CircularProgress, TextField} from "@mui/material";
import {assetUrl} from "../services/image";

export interface UserSelectorProps {
    className?: string,
    onSelect: (user: User) => void
}

function UserSelector(props: UserSelectorProps) {
    const [inputValue, setInputValue] = React.useState("");
    const {data, isLoading} = useSearchUsersQuery(inputValue, {skip: inputValue.length < 2});
    const [selectedUser, setSelectedUser] = React.useState<string | User | null>("");

    let options: User[] = [];
    if (data) {
        options = data.data.result.map((key: number) => {
            if (data.data.entities.users) {
                return data.data.entities.users[key];
            } else {
                return null;
            }
        }).filter((user: User | null) => user);
    }

    return (<div className={props.className}>
            <div className={"grow flex flex-col mt-4"}>
                <Autocomplete
                    value={selectedUser}
                    onChange={(event: any, newValue: string | User | null) => {
                        props.onSelect(newValue as User);
                        setSelectedUser(newValue);
                    }}
                    disablePortal
                    id="search-users-input"
                    selectOnFocus
                    clearOnBlur
                    autoHighlight
                    handleHomeEndKeys
                    options={options}
                    getOptionLabel={(user: User | string) => {
                        return typeof user === 'string' ? "" : (user?.first_name + " " + user?.last_name);
                    }}
                    filterOptions={(x) => x}
                    freeSolo
                    loading={isLoading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onChange={(e) => setInputValue(e.currentTarget.value)}
                            label="Search Users..."
                            variant="filled"
                            autoComplete={"off"}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {isLoading ? <CircularProgress color="primary" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }}
                        />
                    )}
                    renderOption={(props, option: User) => <Box component="li" {...props} key={option.id}>
                        {option.avatar_url &&
                            <Avatar sx={{width: 45, height: 45, mr: 2, flexShrink: 0}} src={assetUrl(option.avatar_url)}/>
                        }
                        {!option.avatar_url &&
                            <Avatar sx={{width: 45, height: 45, mr: 2, flexShrink: 0}}>{option.short_name}</Avatar>
                        }
                        {option.first_name + " " + option.last_name}
                    </Box>
                    }
                />
            </div>
        </div>
    );
}

export default UserSelector;
