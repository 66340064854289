import Header from "../ui/Header";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectLiftVariationEntities, selectTopSetEntities, selectWorkoutSessionById} from "../features/entities/entitiesSlice";
import {useAppDispatch} from "../app/hooks";
import {brawnApi} from "../services/apiClient";
import {RootState} from "../app/store";
import {TopSet} from "../Interfaces";
import _ from "lodash";
import {Button, Typography, useTheme} from "@mui/material";
import {Routes} from "../RouteConstants";
import LogoutButton from "../ui/LogoutButton";
import React, {useEffect} from "react";
import {eventLogger} from "../services/eventLogger";

function WorkoutSessionSummary() {
    const [tracked, setTracked] = React.useState(false);
    const theme = useTheme();
    useEffect(() => {
        if (!tracked) {
            eventLogger.track("pwa_great_lift_view");
            setTracked(true);
        }
    }, [tracked])
    let params = useParams();
    let workoutSessionId = params['workoutSessionId'] ? +params['workoutSessionId'] : -1;
    let dispatch = useAppDispatch();
    let workoutSession = useSelector((state: any) => selectWorkoutSessionById(state.entities, +workoutSessionId));
    if (!workoutSession && workoutSessionId) {
        dispatch(brawnApi.endpoints.getWorkoutSession.initiate(workoutSessionId));
    }
    let topSetEntities = useSelector((state: RootState) => selectTopSetEntities(state.entities));
    let liftVariationEntities = useSelector((state: RootState) => selectLiftVariationEntities(state.entities));
    let topSets: TopSet[] = [];
    if (workoutSession && topSetEntities) {
        topSets = workoutSession.top_sets.map(id => (typeof id === 'number' ? topSetEntities[id] : id) as TopSet);
    }
    let lifts: any = {};
    let volume = 0;
    topSets.forEach(topSet => {
        let lift = lifts[topSet.lift_variation_id] ? lifts[topSet.lift_variation_id] : {};
        if (!lift.liftVariation) {
            lift.liftVariation = liftVariationEntities[topSet.lift_variation_id];
        }
        let sets = lift.sets ? lift.sets : [];
        sets.push(topSet);
        lift.sets = sets;
        lifts[topSet.lift_variation_id] = lift;
        volume += (topSet.weight * 1000 * topSet.reps * topSet.sets);
    })
    volume = volume / 1000;

    let text = theme.palette.getContrastText(theme.palette.background.default);
    let liftBlocks = Object.keys(lifts).map(id => {
        let lift = lifts[id];
        let setRows = _.sortBy(lift.sets, "set_number").map(set => {
            return <div className={'grid grid-cols-3 mb-3'} key={set.id}>
                <div className={'font-bold uppercase'} style={{color: theme.workoutSummary.box.table.rowHeaderColour}}>Set {set.set_number}</div>
                <div className={'font-bold'} style={{color: theme.workoutSummary.box.table.cellColour}}>{set.weight}</div>
                <div className={'font-bold'} style={{color: theme.workoutSummary.box.table.cellColour}}>{set.reps}</div>
            </div>
        });
        return <div key={id} className={"px-6 mb-6 py-5"} style={{
            background: theme.workoutSummary.box.background,
            borderRadius: theme.workoutSummary.box.borderRadius,
            boxShadow: theme.workoutSummary.box.boxShadow
        }}>
            <Typography variant={"h3"} style={{color: text}}>{_.startCase(lift.liftVariation.name)}</Typography>
            <div className={'mt-4'}>
                <div className={'grid grid-cols-3'}>
                    <div>&nbsp;</div>
                    <div className={'text-sm'} style={{color: theme.workoutSummary.box.table.headerColour}}>KG</div>
                    <div className={'text-sm'} style={{color: theme.workoutSummary.box.table.headerColour}}>Reps</div>
                </div>
                {setRows}
            </div>
        </div>;
    });
    return <div className={'flex flex-col h-screen'}>
        <Header/>
        <div className={'px-6 grow mt-6 container mx-auto max-w-xl'}>
            <div className={"mb-6"}>
                <div className={'text-center py-5 px-4'} style={{
                    background: theme.workoutSummary.box.background,
                    borderRadius: theme.workoutSummary.box.borderRadius,
                    boxShadow: theme.workoutSummary.box.boxShadow
                }}>
                    <Typography variant={"h1"} className={'uppercase'}><span style={{color: theme.palette.secondary.main}}>Great</span> <span
                        style={{color: theme.palette.getContrastText(theme.palette.background.default)}}>{workoutSession && workoutSession.top_sets.length > 1 ? "Lifts" : "Lift"}!</span>
                    </Typography>
                </div>
                {theme.workoutSummary.box.border &&
                    <div className={"border-b-8 mx-2"} style={{borderBottomColor: theme.palette.primary.main}}></div>
                }
            </div>
            <div>
                {liftBlocks}
            </div>
            <div className={"px-5"}>
                <p className={'font-bold uppercase'} style={{color: theme.workoutSummary.box.totals.titleColour}}>Total Volume Lifted</p>
                <p className={'uppercase mb-6'} style={{color: theme.workoutSummary.box.totals.valueColour}}>{volume}kg</p>
                <p className={'font-bold uppercase'} style={{color: theme.workoutSummary.box.totals.titleColour}}>Total Challenge Contribution</p>
                <p className={'uppercase'} style={{color: theme.workoutSummary.box.totals.valueColour}}>{volume}kg</p>
            </div>
        </div>
        <div className={'p-6'} style={{backgroundColor: theme.footer.background}}>
            <div className={"container mx-auto max-w-xl"}>
                <Link to={Routes.LIFT_ENTRY} onClick={() => eventLogger.track("pwa_great_lift_enter_more")}>
                    <Button fullWidth variant={"contained"} size={"large"} className={'uppercase'}>Enter More Lifts</Button>
                </Link>
                <div className={'mt-4'}>
                    <LogoutButton event_name={"pwa_your_lifts_logout"}/>
                </div>
            </div>
        </div>
    </div>
}

export default WorkoutSessionSummary
