import {createTheme} from "@mui/material";

const smartFitTheme = createTheme({
    logo: "/images/smart-fit-white.svg",
    landing: {
        background: "#212121",
        image: "/images/pure-gym-landing.png",
        textColour: "#FFF"
    },
    header: {
        image: "/images/smart-fit-white.svg",
        imageMaxHeight: "2rem",
        background: "#1f2123",
        padding: {
            top: "25px",
            right: "25px",
            left: "25px",
            bottom: "25px",
        }
    },
    palette: {
        mode: "light",
        primary: {
            main: "#D52B1E"
        },
        secondary: {
            main: "#FFAD12"
        }
    },
    workoutSummary: {
        box: {
            background: "#FFF",
            boxShadow: "0px 0px 6px 4px rgba(0, 0, 0, 0.05)",
            borderRadius: "8px",
            table: {
                headerColour: "#000",
                cellColour: "rgba(0, 0, 0, 0.5)",
                rowHeaderColour: "#FFAD12"
            },
            totals: {
                titleColour: "#000",
                valueColour: "rgba(0,0,0, 0.5)"
            }
        }
    },
    appBackground: "#FFFFFF",
    typography: {
        fontFamily: ['"Gotham"', "sans-serif"].join(","),
        h1: {
            fontFamily: ['"Gotham-Black"', "sans-serif"].join(","),
            fontSize: "48px"
        },
        h2: {
            fontFamily: ['"Gotham-Black"', "sans-serif"].join(","),
            fontSize: "30px"
        },
        h3: {
            fontFamily: ['"Gotham-Black"', "sans-serif"].join(","),
            fontSize: "21px"
        },
        h4: {
            fontFamily: ['"Gotham-Black"', "sans-serif"].join(","),
        },
        button: {
            textTransform: "none"
        }
    },
    footer: {
        background: "#FFF"
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
                font-display: swap;
                font-family: 'Gotham';
                font-weight: 400;
                font-style: normal;
                src: local("Gotham Book"),local("Gotham-Book"),url("//assets.smartfit.com.br/fonts/gotham/GothamHTF-Book.woff"),url("//assets.smartfit.com.br/fonts/gotham/GothamHTF-Book.otf")
            }
            
            @font-face {
                font-display: swap;
                font-family: 'Gotham-Medium';
                font-style: normal;
                src: local("Gotham"),local("Gotham-Medium"),url("//assets.smartfit.com.br/fonts/gotham/GothamHTF-Medium.woff"),url("//assets.smartfit.com.br/fonts/gotham/GothamHTF-Medium.otf")
            }
            
            @font-face {
                font-display: swap;
                font-family: 'Gotham-Bold';
                font-style: normal;
                src: local("Gotham"),local("Gotham-Bold"),url("//assets.smartfit.com.br/fonts/gotham/Gotham-Bold.woff"),url("//assets.smartfit.com.br/fonts/gotham/GothamHTF-Medium.otf")
            }
            
            @font-face {
                font-display: swap;
                font-family: 'Gotham-Black';
                font-style: normal;
                src: local("Gotham Black"),local("Gotham-Black-Regular"),url("//assets.smartfit.com.br/fonts/gotham/Gotham-Black-Regular.woff"),url("//assets.smartfit.com.br/fonts/gotham/Gotham-Black-Regular.ttf")
            }
            `
        },
        MuiButton: {
            styleOverrides: {
                root: ({ownerState}) => ({
                    ...(ownerState.variant === 'text' && {
                        fontFamily: ['"Gotham-Bold"', "sans-serif"].join(","),
                    }),
                    borderRadius: "26px"
                })
            }
        },
    },
});

export default smartFitTheme;
