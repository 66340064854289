import "./BipsDial.css";
import {ReactComponent as BipsIcon} from "../../icons/icon_bips.svg";
import {ReactComponent as InfoIcon} from "../../icons/icon_info.svg";
import {ReactComponent as CloseIcon} from "../../icons/icon_close.svg";
import {min, round} from "lodash";
import {Collapse} from "@mui/material";
import React from "react";
import {Routes} from "../../RouteConstants";
import {Link, useLocation} from "react-router-dom";
import {useGetUserByIdQuery} from "../../services/apiClient";
import {useSelector} from "react-redux";
import {selectUserById} from "../../features/entities/entitiesSlice";

interface BipsDialProps {
    current: number,
    userId: number;
}

function BipsDial(props: BipsDialProps) {
    let [open, setOpen] = React.useState(false);
    let location = useLocation();
    let user = useSelector((state: any) => selectUserById(state.entities, props.userId));
    const [target, setTarget] = React.useState(user ? user.bips_target : 1500);
    React.useEffect(() => {
        if(user){
            setTarget(user.bips_target);
        }
    }, [user]);
    const {
        data,
        isFetching,
        isLoading,
        error
    } = useGetUserByIdQuery(props.userId);
    if (isFetching || isLoading || error || !data) {
        return <></>;
    }
    // strokeoffset 140 = 100% 416 = 0% @ 100px x 100px
    // strokeoffset 77 = 100% 416 = 0% @ 120px x 120px
    let minSO = 77;
    let maxSO = 416;
    let number = min([1, props.current / target]);
    let progress = maxSO - ((number ?? 0) * (maxSO - minSO));
    return <div>
        <div className={"flex justify-center relative"}>
            <Link to={Routes.APP_BIPS_HISTORY.replace(":userId", props.userId.toString())}
                  state={{from: location.pathname}}>
                <div className="flex-grow-0 relative">
                    <svg id="progressWheel">
                        <circle id="track" cx="60" cy="60" r="54"></circle>
                        <circle id="progress" cx="60" cy="60" r="54" strokeDashoffset={progress}>
                            <animate attributeName={"stroke-dashoffset"}
                                     from={416}
                                     to={progress}
                                     dur="2s"
                                     calcMode="linear"></animate>
                        </circle>
                    </svg>
                    <div className={"absolute text-center flex flex-col justify-items-center items-center"} style={{top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                        <BipsIcon/>
                        <p className={"text-white"}><span className={"text-lg"}>{round(props.current, 1)}</span><span className={"text-xs"}>/{target}</span></p>
                        <p className={"text-xs text-white opacity-50"}>BIPs</p>
                    </div>
                </div>
            </Link>
            <div className={"absolute mt-2 -mr-3.5"} style={{top: 0, left: "calc(50% + 60px)"}}>
                <InfoIcon onClick={() => setOpen(!open)}/>
            </div>
        </div>
        <Collapse in={open} timeout={375}>
            <div className={"text-white p-4 relative mb-3 mt-6"} style={{background: "rgba(0,0,0,0.25)", borderRadius: "4px"}}>
                <CloseIcon onClick={() => setOpen(false)} className={"absolute"} style={{top: "1rem", right: "1rem"}}/>
                <p className={"mb-3"}><strong>What are Brawn intensity points?</strong></p>
                <p>BIPs allow lifters to accurately measure weight training performance based on age, gender and bodyweight.</p>
            </div>
        </Collapse>
    </div>
}

export default BipsDial;
