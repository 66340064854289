import {Field, Form, Formik, FormikHelpers} from "formik";
import {object, string} from "yup";
import {TextField} from "formik-mui";
import {Button, useTheme} from "@mui/material";
import React from "react";
import {LoadingButton} from "@mui/lab";
import {authService} from "../services/auth.service";

interface Values {
    email: string
}

interface ForgotPinProps {
    onBack: () => void
}

function ForgotPin(props: ForgotPinProps) {
    const theme = useTheme();
    const [requested, setRequested] = React.useState(false);
    const submit = (values: Values, {setSubmitting}: FormikHelpers<any>) => {
        authService.requestPinReset(values.email)
            .then((response: any) => {
                setSubmitting(false);
                setRequested(true);
            })
            .catch(e => {
                setSubmitting(false);
            });
    };
    if (requested) {
        return <div className={"flex flex-col grow"}>
            <div className={"flex-1 flex flex-col justify-center container mx-auto max-w-xl"}>
                <div className={'-mt-20'}>
                    <h3 className={'uppercase font-bold'} style={{color: theme.palette.primary.main}}>PIN Recovery</h3>
                    <p style={{color: theme.palette.getContrastText(theme.palette.background.default)}}>Success! We have emailed your PIN reset link!</p>
                </div>
            </div>
            <div className={"flex-grow-0 mt-4 pt-4 sticky z-10 -mx-6 px-6 pb-6"} style={{bottom: 0, left: 0, right: 0, backgroundColor: theme.footer.background}}>
                <div className={"container mx-auto max-w-xl"}>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size={"large"}
                        className={'mt-4'}
                        onClick={props.onBack}
                    >Back
                    </Button>
                </div>
            </div>
        </div>
    }
    return (<Formik
        initialValues={{email: ""}}
        validationSchema={object({
            email: string().required("Email is required").email("Email must be a valid email address"),
        })}
        onSubmit={submit}
    >
        {({submitForm, isSubmitting, isValid, dirty}) => (
            <Form className={"flex flex-col grow"}>
                <div className={"flex-1 flex flex-col justify-center container mx-auto max-w-xl"}>
                    <div className={'-mt-20 mb-4'}>
                        <h3 className={'uppercase font-bold'} style={{color: theme.palette.primary.main}}>PIN RECOVERY</h3>
                        <p style={{color: theme.palette.getContrastText(theme.palette.background.default)}}>Please enter your account email below and we’ll send you instructions on
                            how to recover your PIN.</p>
                    </div>
                    <Field component={TextField} data-testid={"reset-pin-email"} id={"resetPinEmail"} name={"email"} label="Email" variant="filled" type={"email"}
                           required
                           fullWidth margin={"dense"}/>
                </div>
                <div className={"flex-grow-0 mt-4 pt-4 sticky z-10 -mx-6 px-6 pb-6"} style={{bottom: 0, left: 0, right: 0, backgroundColor: theme.footer.background}}>
                    <div className={"container mx-auto max-w-xl"}>
                        <div className="mb-4">
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting || !isValid || !dirty}
                                loading={isSubmitting}
                                fullWidth
                                size={"large"}
                                sx={{mt: 1}}
                                type={"submit"}
                                data-testid={"pin-reset-submit"}
                            >
                                Next
                            </LoadingButton>
                        </div>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            size={"large"}
                            className={'mt-4'}
                            onClick={props.onBack}
                        >Back
                        </Button>
                    </div>
                </div>
            </Form>
        )}
    </Formik>);
}

export default ForgotPin;
