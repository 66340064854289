import {Action, combineReducers, configureStore, ThunkAction} from '@reduxjs/toolkit';
import authReducer, {authSlice} from "../features/auth/authSlice";
import {brawnApi} from "../services/apiClient";
import thunkMiddleware from "redux-thunk";
import {logger} from "../middleware/logger";
import {setupListeners} from "@reduxjs/toolkit/query";
import {authorised} from "../middleware/authorised";
import groupReducer, {groupSlice} from "../features/group/groupSlice";
import entitiesSlice from "../features/entities/entitiesSlice";
import {entities} from "../middleware/entities";
import themeReducer, {themeSlice} from "../features/theme/themeSlice";

export const setUpStore = () => configureStore({
    reducer: {
        auth: authSlice.reducer,
        group: groupSlice.reducer,
        theme: themeSlice.reducer,
        entities: entitiesSlice,
        [brawnApi.reducerPath]: brawnApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .prepend(
                thunkMiddleware
            )
            .concat(logger, authorised, entities, brawnApi.middleware)
});

export const store = setUpStore();

setupListeners(store.dispatch);
const rootReducer = combineReducers({auth: authReducer, group: groupReducer, theme: themeReducer, entities: entitiesSlice});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
