import RegisterForm from "./RegisterForm";
import React from "react";
import LoginForm from "./LoginForm";

interface UserDataEntryProps {
    onSubmit: () => void
}

function UserDataEntry(props: UserDataEntryProps) {
    const [hasAccount, setHasAccount] = React.useState(false);
    return <>
        <div className={hasAccount ? "invisible h-0" : "visible flex-1 flex flex-col -mb-6"} hidden={hasAccount}>
            <RegisterForm onUseLogin={() => setHasAccount(true)} onRegister={props.onSubmit}/>
        </div>
        <div className={hasAccount ? "visible flex-1 flex flex-col -mb-6" : "invisible h-0"} hidden={!hasAccount}>
            <LoginForm onCreateAccount={() => setHasAccount(false)} onLogin={props.onSubmit}/>
        </div>
    </>
}

export default UserDataEntry;
