import {useSearchGroupsQuery} from "../services/apiClient";
import React from "react";
import {Group} from "../Interfaces";
import {Autocomplete, Avatar, Box, CircularProgress, TextField} from "@mui/material";
import {assetUrl} from "../services/image";
import {eventLogger} from "../services/eventLogger";

export interface GroupSelectorProps {
    className?: string,
    onSelect: (group: Group) => void
}

function GroupSelector(props: GroupSelectorProps) {
    const [inputValue, setInputValue] = React.useState("");
    const {data, isLoading} = useSearchGroupsQuery(inputValue);
    const [selectedGroup, setSelectedGroup] = React.useState<string | Group | null>(null);

    let options: Group[] = [];
    if (data) {
        options = data.data.result.map((key: number) => {
            if (data.data.entities.groups) {
                return data.data.entities.groups[key];
            } else {
                return null;
            }
        }).filter((group: Group | null) => group);
    }

    return (<form className={props.className}>
            <div className={"grow flex flex-col mt-4"}>
                <Autocomplete
                    value={selectedGroup}
                    onChange={(event: any, newValue: string | Group | null) => {
                        props.onSelect(newValue as Group);
                        setSelectedGroup(newValue);
                    }}
                    disablePortal
                    id="search-groups-input"
                    selectOnFocus
                    clearOnBlur
                    autoHighlight
                    handleHomeEndKeys
                    options={options}
                    getOptionLabel={(group: Group | string) => {
                        return typeof group === 'string' ? "" : group?.display_name;
                    }}
                    filterOptions={(x) => x}
                    freeSolo
                    loading={isLoading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onChange={(e) => {
                                let value = e.currentTarget.value;
                                eventLogger.track("pwa_gym_switcher_search", {value});
                                setInputValue(value);
                            }}
                            label="Search Gyms..."
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {isLoading ? <CircularProgress color="primary" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }}
                        />
                    )}
                    renderOption={(props, option: Group) => <Box component="li" {...props} key={option.id}>
                        {option.avatar_url &&
                            <Avatar sx={{width: 45, height: 45, mr: 2, flexShrink: 0}} src={assetUrl(option.avatar_url)}/>
                        }
                        {!option.avatar_url &&
                            <Avatar sx={{width: 45, height: 45, mr: 2, flexShrink: 0}}>{option.short_name}</Avatar>
                        }
                        {option.display_name}
                    </Box>
                    }
                />
            </div>
        </form>
    );
}

export default GroupSelector;
