import React from "react";
import {Theme, useTheme} from "@mui/material";
interface LogoProps {
    className?: string
}
function Logo(props: LogoProps = {className: ""}) {
    const theme: Theme = useTheme();
    return <img src={theme.header.image} alt={"Brawn"} className={props.className} style={{ maxHeight: theme.header.imageMaxHeight}}/>;
}

export default Logo;
