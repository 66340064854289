import GroupSelector from "../ui/GroupSelector";
import {setGroup, setGroupId} from "../features/group/groupSlice";
import {useAppDispatch} from "../app/hooks";
import React from "react";
import {Group} from "../Interfaces";
import Header from "../ui/Header";
import {Button, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {Routes} from "../RouteConstants";
import {eventLogger} from "../services/eventLogger";

function SelectGroup() {
    const [selectedGroup, setSelectedGroup] = React.useState<Group | string>("");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const handleGroupChange = (group: Group) => {
        setSelectedGroup(group);
    }
    const handleSubmit = () => {
        if (typeof selectedGroup !== 'string') {
            eventLogger.track("pwa_gym_switcher_next");
            dispatch(setGroupId(selectedGroup.id));
            dispatch(setGroup(selectedGroup));
            navigate(Routes.LIFT_ENTRY);
        }
    }
    return <div className={'flex flex-col content-center h-screen'}>
        <Header showBack/>
        <div className={'grow flex flex-col px-6'}>
            <div className={"flex flex-col grow container mx-auto max-w-xl"}>
                <div className={"flex-1 flex flex-col"}>
                    <GroupSelector onSelect={handleGroupChange}/>
                </div>
            </div>
            <div className={"mt-4 pt-4 sticky z-10 -mx-6 px-6 pb-6"} style={{bottom: 0, left: 0, right: 0, backgroundColor: theme.footer.background}}>
                <div className={"container mx-auto max-w-xl"}>
                    <Button variant={'contained'} fullWidth onClick={handleSubmit} disabled={!selectedGroup}><span className={'text-white'}>Next</span></Button>
                </div>
            </div>
        </div>
    </div>
}

export default SelectGroup;
