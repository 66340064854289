import {IconButton, TextField, useTheme} from "@mui/material";
import React, {ChangeEvent} from "react";
import {Set} from "./LiftDataEntry";
import ClearIcon from '@mui/icons-material/Clear';
import {eventLogger} from "../services/eventLogger";

interface SetDataEntryProps {
    setNumber: number,
    set: Set,
    onChange: (set: Set) => void,
    onDelete: () => void,
    errors: any,
    hideDelete: boolean
}

function SetDataEntry(props: SetDataEntryProps) {
    const handleRepChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        eventLogger.track("pwa_your_lifts_reps", {value :e.currentTarget.value})
        handleChange("reps", e);
    }
    const theme = useTheme();

    const handleWeightChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        eventLogger.track("pwa_your_lifts_kg", {value :e.currentTarget.value})
        handleChange("weight", e);
    }

    const handleChange = (key: string, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newSet = {...props.set, [key]: e.currentTarget.value};
        props.onChange(newSet);
    }
    return (
        <div className={'grid grid-cols-9 gap-4 mt-4 content-center'}>
            <div className={'flex flex-col justify-center content-center col-span-2'}>
                <p className={'uppercase font-bold'} style={{color: theme.palette.getContrastText(theme.palette.background.default)}}>Set {props.setNumber}</p>
            </div>
            <div className={"col-span-3"}>
                <TextField label={'kg'} variant={'filled'} value={props.set.weight} onChange={handleWeightChange} type="number" error={!!props.errors && !!props.errors.weight}
                           inputProps={{ inputMode: 'decimal' }}
                           helperText={props.errors && props.errors.weight} className={'uppercase'}/>
            </div>
            <div className={"col-span-3"}>
                <TextField label={'Reps'} variant={'filled'} value={props.set.reps} onChange={handleRepChange} type="number" error={!!props.errors && !!props.errors.reps}
                           inputProps={{ inputMode: 'decimal' }}
                           helperText={props.errors && props.errors.reps} className={'uppercase'}/>
            </div>
            <div className={'flex justify-center content-center'}>
                {!props.hideDelete &&
                    <IconButton onClick={props.onDelete}>
                        <ClearIcon color={"disabled"}/>
                    </IconButton>
                }
            </div>
        </div>
    )
}

export default SetDataEntry;
