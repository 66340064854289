import React from "react";
import {useAppSelector} from "../app/hooks";
import {selectGroupId} from "../features/group/groupSlice";
import GroupNameDisplay from "./GroupNameDisplay";
import {Link, useNavigate} from "react-router-dom";
import {Routes} from "../RouteConstants";
import {ChevronLeft} from "@mui/icons-material";
import {eventLogger} from "../services/eventLogger";
import Logo from "./Logo";
import {Theme, useTheme} from "@mui/material";

interface HeaderProps {
    showBack?: boolean
}

const initialProps: HeaderProps = {
    showBack: false
}

function Header(props: HeaderProps = initialProps) {
    const groupId = useAppSelector(selectGroupId);
    const navigate = useNavigate();
    const theme: Theme = useTheme();

    function handleClick() {
        eventLogger.track("pwa_gym_switcher");
        navigate(Routes.GROUP_SELECT);
    }

    return (
        <header style={{
            backgroundColor: theme.header.background,
        }}>
            <div className={'flex content-center items-center max-w-xl mx-auto ' + (groupId ? "justify-between" : "justify-center")} id={"header"} style={{
                paddingTop: theme.header.padding.top,
                paddingBottom: theme.header.padding.bottom,
                paddingLeft: theme.header.padding.left,
                paddingRight: theme.header.padding.right,
            }}>
                {props.showBack && <Link to={Routes.LANDING} className={'my-auto flex-grow-0'}
                                         style={{color: theme.palette.getContrastText(theme.header.background ? theme.header.background : "#000")}}><ChevronLeft/></Link>}
                <Link to={Routes.LIFT_ENTRY} className={'my-auto grow ' + (groupId ? "pr-3" : "")}><Logo className={groupId ? '' : 'mx-auto'}/></Link>
                {groupId && <GroupNameDisplay onClick={handleClick} className={'text-right w-1/2 pl-3'}/>}
            </div>
        </header>
    )
}

export default Header;
