import {Field, Form, Formik, FormikHelpers} from "formik";
import {authService} from "../services/auth.service";
import {object, ref, string} from "yup";
import {TextField} from "formik-mui";
import {LoadingButton} from "@mui/lab";
import {Button, useTheme} from "@mui/material";
import React from "react";
import {Routes} from "../RouteConstants";
import {Link, useNavigate} from "react-router-dom";
import Header from "../ui/Header";
import {pinLogin} from "../features/auth/authSlice";
import {useAppDispatch} from "../app/hooks";

interface Values {
    pin: string,
    pin_confirmation: string,
}

function ResetPin() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [error, setError] = React.useState("");
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    let email = params.get("email");
    let token = params.get("token");
    const theme = useTheme();
    const submit = (values: Values, {setSubmitting}: FormikHelpers<any>) => {
        if (!email || !token) {
            return;
        }
        authService.resetPin({...values, email, token})
            .then(() => {
                dispatch(pinLogin({email: email as string, pin: values.pin}))
                    .unwrap()
                    .then(() => {
                        setSubmitting(false);
                        navigate(Routes.LIFT_ENTRY);
                    })
                    .catch((e: any) => {
                        setSubmitting(false);
                        setError(e.data.message);
                    });
                setError("");
            })
            .catch((e: any) => {
                setSubmitting(false);
                setError(e.data.message);
            });
    };
    if (!email || !token) {
        return <div className={'flex flex-col content-center h-screen max-w-xl mx-auto'}>
            <Header/>
            <div className={'container mx-auto grow flex flex-col p-6'}>
                <div className={"flex flex-col grow"}>
                    <div className={"flex-1 flex flex-col justify-center container mx-auto max-w-xl"}>
                        <div className={'-mt-20 mb-4 px-6'}>
                            <h3 className={'uppercase font-bold'} style={{color: theme.palette.primary.main}}>PIN Recovery</h3>
                            <p style={{color: theme.palette.getContrastText(theme.palette.background.default)}}>This link is invalid, please check your email for the correct link,
                                or contact support for more help.</p>
                        </div>
                    </div>
                </div>
                <div className={"flex-grow-0 mt-4 pt-4 sticky z-10 -mx-6 px-6 pb-6"} style={{bottom: 0, left: 0, right: 0, backgroundColor: theme.footer.background}}>
                    <div className={"container mx-auto max-w-xl"}>
                        <Link to={Routes.LIFT_ENTRY}>
                            <Button fullWidth variant={'outlined'}>Ok</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>;
    }

    return (<div className={'flex flex-col content-center h-screen max-w-xl mx-auto'}>
        <Header/>
        <div className={'grow flex flex-col p-6'}>
            <div className={"flex flex-col grow"}>
                <div className={"flex-1 flex flex-col justify-center"}>
                    <Formik
                        initialValues={{pin: "", pin_confirmation: ""}}
                        validationSchema={object({
                            pin: string().required("Pin is required"),
                            pin_confirmation: string().required("Please confirm your pin").oneOf([ref("pin"), null], "Pins don't match"),
                        })}
                        onSubmit={submit}
                    >
                        {({submitForm, isSubmitting, isValid, dirty}) => (
                            <Form className={"flex flex-col grow"}>
                                <div className={"flex-1 flex flex-col justify-center container mx-auto max-w-xl"}>
                                    <div className={'-mt-20 mb-4'}>
                                        <h3 className={'text-red-600 uppercase font-bold'}>PIN RECOVERY</h3>
                                        <p className={'text-white'}>Please enter your new pin below to reset it.</p>
                                    </div>
                                    <Field component={TextField} id={"resetPinNewPin"} name={"pin"} label="Set a PIN" variant="filled" type={"password"} required fullWidth
                                           margin={"dense"}/>
                                    <Field component={TextField} id={"resetPinConfirmNewPin"} name={"pin_confirmation"} label="Confirm PIN" variant="filled" type={"password"}
                                           required
                                           error={error.length > 0}
                                           helperText={error ? error : "Your PIN must contain between 4 and 8 numbers"}
                                           fullWidth
                                           margin={"dense"}/>
                                </div>
                                <div className={"flex-grow-0 mt-4 pt-4 sticky z-10 -mx-6 px-6 pb-6"}
                                     style={{bottom: 0, left: 0, right: 0, backgroundColor: theme.footer.background}}>
                                    <div className={"container mx-auto max-w-xl"}>
                                        <div className="mb-4">
                                            <LoadingButton
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting || !isValid || !dirty}
                                                loading={isSubmitting}
                                                fullWidth
                                                size={"large"}
                                                sx={{mt: 1}}
                                                type={"submit"}
                                                data-testid={"pin-reset-submit"}
                                            >
                                                Next
                                            </LoadingButton>
                                        </div>
                                        <Link to={Routes.LIFT_ENTRY}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                fullWidth
                                                size={"large"}
                                                className={'mt-4'}
                                            >
                                                <span className={'text-white'}>Back</span>
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    </div>);
}

export default ResetPin;
