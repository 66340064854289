import {useRegisterMutation} from "../services/apiClient";
import {useAppDispatch} from "../app/hooks";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {pinLogin} from "../features/auth/authSlice";
import {boolean, object, ref, string} from "yup";
import {CheckboxWithLabel, TextField} from "formik-mui";
import {Button, useTheme} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {eventLogger} from "../services/eventLogger";

export interface UserSubmission {
    first_name: string,
    last_name: string,
    pin: string,
    pin_confirmation: string,
    email: string,
    accepted_terms: boolean;
}

interface RegisterProps {
    onUseLogin: () => void,
    onRegister: () => void
}

function RegisterForm(props: RegisterProps) {
    const [register, {isLoading: registering}] = useRegisterMutation();
    let dispatch = useAppDispatch();
    const submit = (values: UserSubmission, {setSubmitting, setFieldError}: FormikHelpers<any>) => {
        if (!registering) {
            eventLogger.track("pwa_about_you_next", values);
            register(values).unwrap()
                .then(() => {
                    dispatch(pinLogin({email: values.email, pin: values.pin}))
                        .then((response: any) => {
                            setSubmitting(false);
                            if (response.type === "auth/pin-login/fulfilled") {
                                props.onRegister();
                            }
                        });
                })
                .catch(error => {
                    setSubmitting(false);
                    Object.keys(error.data.errors).forEach(key => {
                        setFieldError(key, error.data.errors[key][0]);
                    });
                });
        }
    };
    const theme = useTheme();
    let initialValues: UserSubmission = {
        first_name: "",
        last_name: "",
        email: "",
        pin: "",
        pin_confirmation: "",
        accepted_terms: false
    };
    return <Formik
        initialValues={initialValues}
        validationSchema={object({
            first_name: string().required("First name is required"),
            last_name: string().required("Last name is required"),
            email: string().required("Email address is required").email("Email address must be a valid email address"),
            pin: string().required("Pin is required"),
            pin_confirmation: string().required("Please confirm your pin").oneOf([ref("pin"), null], "Pins don't match"),
            accepted_terms: boolean().isTrue("You need to accept the terms and conditions")
        })}
        onSubmit={submit}
    >
        {({submitForm, isSubmitting, setFieldValue, values, isValid, dirty}) => (
            <Form className={"flex flex-col grow"}>
                <div className={"flex-1 flex flex-col justify-center container mx-auto max-w-xl"}>
                    <Field component={TextField} id={"registerFirstName"} name={"first_name"} label="First Name" variant="filled" required fullWidth margin={"dense"}/>
                    <Field component={TextField} id={"registerLastName"} name={"last_name"} label="Last Name" variant="filled" required fullWidth margin={"dense"}/>
                    <Field component={TextField} id={"registerEmailAddress"} name={"email"} label="Email" variant="filled" type={"email"} required fullWidth
                           margin={"dense"}/>
                    <Field component={TextField} id={"registerPin"} name={"pin"} label="Set a PIN" variant="filled" type={"password"} required fullWidth margin={"dense"}/>
                    <Field component={TextField} id={"registerConfirmPin"} name={"pin_confirmation"} label="Confirm PIN" variant="filled" type={"password"} required
                           helperText="Your PIN must contain between 4 and 8 numbers"
                           fullWidth
                           margin={"dense"}/>
                    <Field type={"checkbox"}
                           component={CheckboxWithLabel}
                           id={"registerAcceptTermsAndConditions"}
                           name={"accepted_terms"}
                           data-testid={'accept-terms'}
                           Label={{
                               label: <span style={{color: theme.palette.getContrastText(theme.palette.background.default)}}>I agree to the <a
                                   href={"https://brawn.co.uk/terms-and-conditions"} target={"_blank"}
                                   rel={"nofollow noreferrer noopener"}
                                   className={"font-bold"} style={{color: theme.palette.secondary.main}}>terms &amp; conditions</a></span>
                           }}/>
                </div>
                <div className={"flex-grow-0 mt-4 pt-4 sticky z-10 -mx-6 px-6 pb-6"} style={{bottom: 0, left: 0, right: 0, backgroundColor: theme.footer.background}}>
                    <div className={"container mx-auto max-w-xl"}>
                        <div className="mb-4">
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                disabled={!isValid || !dirty}
                                loading={isSubmitting}
                                type={"submit"}
                                fullWidth
                                data-testid={"register-submit-button"}
                                size={"large"}
                            >Next</LoadingButton>
                        </div>
                        <Button variant={'outlined'} fullWidth onClick={() => {
                            eventLogger.track("pwa_about_you_have_account");
                            props.onUseLogin();
                        }} size={"large"}>I have an account</Button>
                    </div>
                </div>
            </Form>
        )}
    </Formik>
}

export default RegisterForm;
