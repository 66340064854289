import {IconButton, useTheme} from "@mui/material";
import {ChevronLeft} from "@mui/icons-material";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useGetMeQuery, useUpdateBipsTargetMutation} from "../../services/apiClient";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {TextField} from "formik-mui";
import {LoadingButton} from "@mui/lab";
import {Routes} from "../../RouteConstants";
import {useSelector} from "react-redux";
import {selectUserById} from "../../features/entities/entitiesSlice";
import {authService} from "../../services/auth.service";
import {User} from "../../Interfaces";

interface UpdateBipsTargetPayload {
    bips_target: number;
}

interface LocationState {
    from?: string
}

function IntegrationBipsTarget() {
    let navigate = useNavigate();
    let theme = useTheme();
    const location = useLocation();
    let state = location.state as LocationState;
    const [updateBipsTarget, {isLoading: updating}] = useUpdateBipsTargetMutation();
    let userId: number;
    let user: User|undefined = authService.retrieveUserFromStorage();
    userId = user.id;
    user = useSelector((state: any) => selectUserById(state.entities, +userId));
    const [target, setTarget] = React.useState(user ? user.bips_target : 1500);
    let {data} = useGetMeQuery(null);
    React.useEffect(() => {
        if (user) {
            setTarget(user.bips_target);
        }
    }, [user]);
    if (data && data.data.entities && data.data.entities.users) {
        userId = data.data.result;
        user = data.data.entities.users[userId];
    } else {
        return <></>
    }
    const submit = (values: UpdateBipsTargetPayload, {setSubmitting, setFieldError}: FormikHelpers<any>) => {
        if (!updating) {
            updateBipsTarget(values).unwrap()
                .then(data => {
                    navigate(Routes.APP_BIPS_HISTORY.replace(":userId", data.data.result), {state})
                })
                .catch(error => {
                    setSubmitting(false);
                    Object.keys(error.data.errors).forEach(key => {
                        setFieldError(key, error.data.errors[key][0]);
                    });
                });
        }
    };
    let initialValues: UpdateBipsTargetPayload = {bips_target: target};

    let form = <Formik initialValues={initialValues} onSubmit={submit}>
        {({submitForm, isSubmitting, setFieldValue, values, isValid, dirty}) => (
            <Form className={"flex flex-col grow"}>
                <div className={"flex-1 flex flex-col container mx-auto max-w-xl"}>
                    <Field component={TextField}
                           id={"bipsTarget"} name={"bips_target"}
                           label="Session BIPs goal" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                           variant="filled" required fullWidth
                           margin={"dense"} min={0}/>
                </div>
                <div className={"flex-grow-0 mt-4 pt-4 sticky z-10 -mx-6 px-6 pb-6"} style={{bottom: 0, left: 0, right: 0, backgroundColor: theme.footer.background}}>
                    <div className={"container mx-auto max-w-xl"}>
                        <div className="mb-4">
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                disabled={!isValid || !dirty}
                                loading={isSubmitting}
                                type={"submit"}
                                fullWidth
                                data-testid={"update-bips-target-submit-button"}
                                size={"large"}>
                                <strong>Done</strong>
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </Form>
        )}
    </Formik>

    return <div className={'flex flex-col h-screen'}>
        <div className={'px-6 grow container mx-auto max-w-xl flex flex-col'}>
            <div className="flex items-center py-6">
                <IconButton className={'flex-none'} onClick={() => navigate(Routes.APP_BIPS_HISTORY.replace(":userId", userId.toString()), {state})}><ChevronLeft/></IconButton>
                <h3 className={'flex-grow text-center text-lg'}><strong>Training Goals</strong></h3>
                <div style={{width: "40px"}}/>
            </div>
            <div className={'flex-grow'}>
                {form}
            </div>
        </div>
    </div>
}

export default IntegrationBipsTarget;
