import React from "react";
import {useAppDispatch} from "../../app/hooks";
import {useSelector} from "react-redux";
import {selectInsightsEntities, selectLiftVariationEntities, selectTopSetEntities, selectWorkoutSessionById} from "../../features/entities/entitiesSlice";
import {brawnApi} from "../../services/apiClient";
import {RootState} from "../../app/store";
import {Insight} from "../../Interfaces";
import {round} from "lodash";

interface InsightsBoxProps {
    workoutSessionId: number;
    className?: string;
}

const nth = function (d: number) {
    const dString = String(d);
    const last = +dString.slice(-2);
    if (last > 3 && last < 21) return 'th';
    switch (last % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
}

function InsightsBox(props: InsightsBoxProps) {
    const {workoutSessionId} = props;
    let dispatch = useAppDispatch();
    let workoutSession = useSelector((state: any) => selectWorkoutSessionById(state.entities, +workoutSessionId));
    React.useEffect(() => {
        dispatch(brawnApi.endpoints.getWorkoutSessionInsights.initiate(workoutSessionId));
        if (!workoutSession && workoutSessionId) {
            dispatch(brawnApi.endpoints.getWorkoutSession.initiate(workoutSessionId));
        }
    }, [dispatch, workoutSessionId, workoutSession]);
    let insightEntities = useSelector((state: RootState) => selectInsightsEntities(state.entities));
    let topSetEntities = useSelector((state: RootState) => selectTopSetEntities(state.entities));
    let liftVariationEntities = useSelector((state: RootState) => selectLiftVariationEntities(state.entities));
    let insights = Object.keys(insightEntities)
        .filter((index: string) => {
            const insight = insightEntities[index];
            return insight ? +insight.meta.workout_session_id === +workoutSessionId : false;
        })
        .map(index => insightEntities[index]);

    if (insights.length === 0) {
        return <></>;
    }
    let sessionsInsight = insights.filter((insight: Insight | undefined) => insight && insight.key === 'sessions_in_7_days')[0];
    let bipsImprovementInsight = insights.filter((insight: Insight | undefined) => insight && insight.key === 'bips_improvement')[0];
    let volumeImprovementInsight = insights.filter((insight: Insight | undefined) => insight && insight.key === 'volume_improvement')[0];
    let mostIntenseSetInsight = insights.filter((insight: Insight | undefined) => insight && insight.key === 'most_intense_set')[0];
    let avgBipsScoreInsight = insights.filter((insight: Insight | undefined) => insight && insight.key === 'average_bips_score')[0];
    let mostIntenseSet = null;
    let mostIntenseSetLiftVariation = null;
    if (mostIntenseSetInsight && mostIntenseSetInsight.top_set) {
        mostIntenseSet = topSetEntities[+mostIntenseSetInsight.top_set];
        if (mostIntenseSet) {
            mostIntenseSetLiftVariation = liftVariationEntities[mostIntenseSet.lift_variation_id];
        }
    }
    return <div className={props.className}>
        <h3 className={'text-xl mb-3'}><strong>Insights</strong></h3>
        <ul className={"list-disc px-6 text-white/75"}>
            {sessionsInsight &&
                <li className={"mb-3"}>This is your <strong className={'text-white'}>{sessionsInsight.value.toLocaleString()}{nth(sessionsInsight.value)}</strong> session in 7 days
                </li>
            }
            {bipsImprovementInsight && bipsImprovementInsight.value > 0 &&
                <li className={"mb-3"}>You achieved <strong className={'text-white'}>{bipsImprovementInsight.value.toLocaleString()} {bipsImprovementInsight.unit}</strong> more
                    than last time</li>
            }
            {volumeImprovementInsight && volumeImprovementInsight.value > 0 &&
                <li className={"mb-3"}>You lifted <strong className={'text-white'}>{volumeImprovementInsight.value.toLocaleString()}{volumeImprovementInsight.unit}</strong> more
                    than last time</li>
            }
            {mostIntenseSetInsight && mostIntenseSet && mostIntenseSetLiftVariation &&
                <li className={"mb-3"}>Your most intense set
                    was <strong className={'text-white'}>{mostIntenseSetLiftVariation.name}: {mostIntenseSet.reps.toLocaleString()} x {mostIntenseSet.weight.toLocaleString()}kg
                        ({mostIntenseSet.bips.toLocaleString()} BIPs)</strong>
                </li>
            }
            {avgBipsScoreInsight &&
                <li className={"mb-3"}>Your average set BIPs score was <strong className={'text-white'}>{round(avgBipsScoreInsight.value.toLocaleString())}</strong></li>
            }
        </ul>
    </div>;
}

export default InsightsBox;
