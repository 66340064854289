import {Box} from "@mui/material";
import React from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    className?: string
}

export function TabPanel(props: TabPanelProps) {
    const {children, value, index, className, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={"flex flex-col " + (value !== index ? "h-0" : "") + " " + className}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}} className={'grow flex flex-col'}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

