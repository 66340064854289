import {useAppDispatch} from "../app/hooks";
import {clientLogin, logout, sanctumLogout} from "../features/auth/authSlice";
import {useNavigate} from "react-router-dom";
import {Routes} from "../RouteConstants";
import React from "react";
import {LoadingButton} from "@mui/lab";
import {eventLogger} from "../services/eventLogger";
import {setGroup, setGroupId} from "../features/group/groupSlice";

interface LogoutButtonProps {
    event_name?: string
}

function LogoutButton(props: LogoutButtonProps) {
    const {event_name = "pwa_your_lifts_logout"} = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loggingOut, setLoggingOut] = React.useState(false);
    const handleLogout = async () => {
        setLoggingOut(true);
        await dispatch(sanctumLogout());
        await dispatch(logout());
        await dispatch(clientLogin());
        await dispatch(setGroupId(""));
        await dispatch(setGroup(""));
        eventLogger.track(event_name);
        navigate(Routes.LANDING);
        setLoggingOut(false);
    }
    return <LoadingButton loading={loggingOut} size={"large"} variant={"outlined"} color={'primary'} className={'uppercase'} fullWidth onClick={handleLogout}>
        {loggingOut ? <span>&nbsp;</span> : <span>Logout</span>}
    </LoadingButton>
}

export default LogoutButton;
