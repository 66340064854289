import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";

interface ThemeState {
    theme: string | null
}

let initialState: ThemeState = {
    theme: localStorage.getItem("theme") ? localStorage.getItem("theme") : "brawn"
};
export const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setTheme: (state, action) => {
            let theme = action.payload;
            state.theme = theme;
            localStorage.setItem('theme', theme);
            return state;
        },
    }
});

export const selectTheme = (state: RootState) => state.theme.theme;
export const {setTheme} = themeSlice.actions;
export default themeSlice.reducer;
