import {Box, Tabs, Theme, useTheme} from "@mui/material";
import {a11yProps, TabPanel} from "../ui/TabPanel";
import React from "react";
import BrawnTab from "../ui/BrawnTab";
import LiftsDataEntry, {LiftsSubmission} from "../ui/LiftsDataEntry";
import UserDataEntry from "../ui/UserDataEntry";
import {useCreateWorkoutSessionMutation} from "../services/apiClient";
import Header from "../ui/Header";
import {useAppSelector} from "../app/hooks";
import {selectGroupId} from "../features/group/groupSlice";
import {useNavigate} from "react-router-dom";
import {selectUser} from "../features/auth/authSlice";

interface WorkoutSessionPayload {
    groups?: number[],
    scope?: number,
    lifts: {
        lift_variation_id: number,
        sets: {
            set_number: number,
            reps: number,
            weight: number
        }[]
    }[]
}


export function liftsDataIsValid(liftsData: LiftsSubmission) {
    if (liftsData.lifts.length === 0) {
        return false;
    }
    let valid = true;
    liftsData.lifts.forEach(lift => {
        if (!lift.liftVariation) {
            valid = false;
        }
        if (lift.sets.length === 0) {
            valid = false;
        }
        lift.sets.forEach(set => {
            if (!(set.reps > 0)) {
                valid = false;
            }
            if (!(set.weight > 0)) {
                valid = false;
            }
        });
    });
    return valid;
}

function LiftEntry() {
    const [value, setValue] = React.useState(0);
    const [errors, setErrors] = React.useState("");
    const [liftsData, setLiftsData] = React.useState<LiftsSubmission>({lifts: [{liftVariation: "", sets: [{weight: "", reps: ""}]}]});
    const [createWorkoutSession] = useCreateWorkoutSessionMutation();
    const groupId = useAppSelector(selectGroupId);
    const navigate = useNavigate();
    const user = useAppSelector(selectUser);
    const [saving, setSaving] = React.useState(false);
    const theme: Theme = useTheme();
    React.useEffect(() => {
        if (groupId && (!liftsData.groups || +groupId !== +liftsData.groups[0])) {
            setLiftsData({...liftsData, groups: [+groupId]});
        }
    }, [groupId, liftsData]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleLiftSubmit = (data: LiftsSubmission) => {
        setLiftsData(data);
        user ? handleUserSubmit() : setValue(1);
    }

    function handleUserSubmit() {
        setValue(0);
        setSaving(true);
        let groups = liftsData.groups ? liftsData.groups : [];
        let payload = {
            groups: groups,
            scope: groups.length > 0 ? 2 : 1,
            lifts: liftsData.lifts.map(lift => {
                return {
                    lift_variation_id: typeof lift.liftVariation !== 'string' ? lift.liftVariation.id : lift.liftVariation,
                    sets: lift.sets.map((set, i) => {
                        return {
                            set_number: i + 1,
                            weight: set.weight,
                            reps: set.reps
                        }
                    })
                }
            })
        } as WorkoutSessionPayload;
        createWorkoutSession(payload).unwrap()
            .then(response => {
                setSaving(false);
                navigate(`/workout-session/${response.data.result}/summary`);
            })
            .catch(error => {
                setSaving(false);
                setValue(0);
                setErrors(error.data.errors);
            });
    }

    return <div className={'flex flex-col h-screen mx-auto'}>
        <div className={'flex-grow-0'}>
            <Header/>
        </div>
        <div className={'grow flex flex-col'}>
            <Box sx={{borderBottom: 1, borderColor: 'transparent', backgroundColor: theme.header.background}}>
                <Tabs
                    className={"container mx-auto max-w-xl"}
                    variant={'fullWidth'}
                    value={value}
                    onChange={handleChange}
                    sx={{px: 3}}
                    centered>
                    <BrawnTab label="Your Lifts" {...a11yProps(0)} value={0}/>
                    {(!user || (user && saving)) &&
                        <BrawnTab label="About You" {...a11yProps(1)} value={1} disabled={!liftsDataIsValid(liftsData)}/>
                    }
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} className={value === 0 ? " grow" : ""}>
                <LiftsDataEntry onSubmit={handleLiftSubmit} data={liftsData} errors={errors} saving={saving}/>
            </TabPanel>
            <TabPanel value={value} index={1} className={value === 1 ? "grow" : ""}>
                <UserDataEntry onSubmit={handleUserSubmit}/>
            </TabPanel>
        </div>
    </div>
}

export default LiftEntry;
